import React, { useEffect, useState, Fragment } from 'react';
import { useParams } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { ButtonGroup, Button, Col, Card } from 'react-bootstrap';
import { GlobalSet } from '../../global/GlobalProvider';

const Home = () => {
  const [onHomeGame, setOnHomeGame] = useState([]);
  const [loading, setLoading] = useState(true);
  const { slug } = useParams();
  const { BaseUrl } = GlobalSet();
  
useEffect(()=>{
const data = {
  key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
  slug:slug,
  }
  axios.post('/bonus-open',data)
  .then(response=>{
       if(response.data.status === 'success')
       {
         setOnHomeGame(response.data.data)
       }
       else if(response.data.status === 'fail')
       {
         swal("Warning",response.data.message,"warning");
       }   
   })
 .catch(error=>{
   console.log(error)
 })
},[slug]);






  return (
<>
   <span style={{  marginTop: '-36px' }} className="d-xl-block">
      <div className="row">
      <Fragment>
          <Col xl="12" className="p-0" >
            <Card>


              <div className="form-inline">
                <div className="row">

                   <div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12" key={onHomeGame.titel}>

                    <div className="card project-boxed">
                      <div className="img-bx">
                        <img
                          src={BaseUrl + onHomeGame.blog_image}
                          alt=""
                          className="me-3 card-list-img w-100"
                          width="130"
                        />
                        <h4 className="mb-2 card-header align-items-start text-center">{onHomeGame.titel}</h4>
                      </div>
                      <div className="card-header align-items-start">

                          <div
                            dangerouslySetInnerHTML={{ __html: onHomeGame.Discription }}
                          />
                      </div>
                      <div className="card-body p-0 pb-3">
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item">
                            <span className="mb-0 title">Deadline Date</span> :
                            <span className="text-black ms-2">{onHomeGame.created_at}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
              </div>
              </div>









            </Card>
          </Col>
      </Fragment>

      </div>
    </span>
    </>
  );
};

export default Home;
