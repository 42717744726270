import React, { Fragment,useState,useEffect } from "react";
import { Form } from "react-bootstrap";
import PageTitle from "../../layouts/PageTitle";
import { Redirect , useHistory, useParams} from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";

const Checkout = () => {
const history = useHistory();
const { id } = useParams();
const [deposit, setDeposit] = useState([]);

useEffect(()=>{
const data = {
  key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
  payment_id:id,
  }
  axios.post('/apps-deposit-get',data)
  .then(response=>{
       if(response.data.status === 'success')
       {
         setDeposit(response.data.data)
       }
       else if(response.data.status === 'fail')
       {
         swal("Warning",response.data.message,"warning");
         history.push('/deposit');
       }
       else 
       {
       setDepositInput({...depositInput, error_list: response.data.validation_error});
       }    
   })
 .catch(error=>{
   console.log(error)
 })
},[id]);

const [depositInput, setDepositInput] = useState({
  amount: '',
  transaction_sender_id: '',
  sender_number: '',
  error_list: [],
});

const handleInput = (e) => {
  e.persist();
  setDepositInput({...depositInput, [e.target.name]: e.target.value });
}

const depositSubmit = (e) => {
  e.preventDefault();
  const auth_token = localStorage.getItem('auth_token');
  const data = {
      amount: depositInput.amount,
      transaction_sender_id: depositInput.transaction_sender_id,
      sender_number: depositInput.sender_number,
      key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
      gateway_id:id,
  }
 if (auth_token !== null) {
 axios.post(`/apps-deposit-sub`, data).then(res => {
       if(res.data.status === 'success')
       {
         swal("Success",res.data.message,"success");
         setDepositInput({...depositInput,
           amount:'',
           sender_number:'',
           transaction_sender_id:'',
           
         });
       }
       else if(res.data.status === 'fail')
       {
         swal("Warning",res.data.message,"warning");
       }
       else 
       {
       setDepositInput({...depositInput, error_list: res.data.validation_error});
       }
   });
 }
}

   return (
      <Fragment>
         <div className="row">
            <div className="col-xl-12">
               <div className="card">
                  <div className="card-body">
                     <div className="row">
                        <div className="col-md-4 order-md-2 mb-4">
                           <h4 className="d-flex justify-content-between align-items-center mb-3">
                              <span className="text-muted">Deposit Balance</span>
                              <span className="badge badge-primary badge-pill">
                                 {deposit.currency_symbols}
                              </span>
                           </h4>
                           <ul className="list-group mb-3">
                              <li className="list-group-item d-flex justify-content-between lh-condensed">
                                 <div>
                                    <h6 className="my-0">{deposit.Payment_name}</h6>
                                    <small className="text-muted">
                                       {deposit.Payment_name} payment
                                    </small>
                                 </div>
                                 <span className="text-muted">100%</span>
                              </li>
                              <li className="list-group-item d-flex justify-content-between lh-condensed">
                                 <div>
                                    <h6 className="my-0">{deposit.Payment_receiver}</h6>
                                    <small className="text-muted">
                                       {deposit.Payment_name} payment
                                    </small>
                                 </div>
                                 <span className="text-muted">100%</span>
                              </li>
                              <li className="list-group-item d-flex justify-content-between lh-condensed">
                                 <div>
                                    <small className="text-muted">
                                       Deposit Limit
                                    </small>
                                    <h6 className="my-0">Min: {deposit.currency_name} {deposit.min_amount} And Max {deposit.currency_name} { deposit.max_amount} </h6>
                                 </div>
                              </li>
                              <li className="list-group-item d-flex justify-content-between lh-condensed">
                                 <div>
                                    <small className="text-muted">
                                       {deposit.Payment_name} payment
                                    </small>                                 
                                    <h6 className="my-0">{deposit.Payment_details}</h6>

                                 </div>
                              </li>
                           </ul>
                        </div>
                        <div className="col-md-8 order-md-1">
                           <h4 className="mb-3">Deposit Balance</h4>
                           <form onSubmit={depositSubmit} className="needs-validation" noValidate="">
                              <div className="row">
                                 <div className="col-md-6 mb-3">
                                    <label htmlFor="lastName">Amount</label>
                                    <input
                                       type="text"
                                       className="form-control"
                                       id="lastName"
                                       placeholder={"Type "+ deposit.currency_name + " Amount " + " ( " + deposit.currency_symbols + " )"}
                                       required
                                       name="amount"
                                       onChange={handleInput} 
                                       value={depositInput.amount}
                                    />
                                       <span className="text-danger">{depositInput.error_list.amount}</span>
                                 </div>

                                 <div className="col-md-6 mb-3">
                                    <label htmlFor="firstName">
                                       Transaction Sender Id
                                    </label>
                                    <input
                                       type="text"
                                       className="form-control"
                                       id="firstName"
                                       placeholder="Transaction Id"
                                       required
                                       name="transaction_sender_id"
                                       onChange={handleInput} 
                                       value={depositInput.transaction_sender_id}
                                    />
                                       <span className="text-danger">{depositInput.error_list.transaction_sender_id}</span>
                                    
                                 </div>

                                 <div className="col-md-12 mb-3">
                                    <label htmlFor="firstName">
                                       Sender Number
                                    </label>
                                    <input
                                       type="text"
                                       className="form-control"
                                       id="firstName"
                                       placeholder="Sender Number"
                                       required
                                       name="sender_number"
                                       onChange={handleInput} 
                                       value={depositInput.sender_number}
                                    />
                                       <span className="text-danger">{depositInput.error_list.sender_number}</span>
                                    
                                 </div>
                              </div>

                              <hr className="mb-4" />
                              <div className="form-check custom-checkbox mb-2">
                                 <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="same-address"
                                    required

                                 />
                                 <label
                                    className="form-check-label"
                                    htmlFor="same-address"
                                 >
                                    Accept treams & condition
                                 </label>
                              </div>
                              <hr className="mb-4" />
                              <button
                                 className="btn btn-primary btn-lg btn-block"
                                 type="submit"
                              >
                                 Continue to Deposit
                              </button>
                           </form>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </Fragment>
   );
};

export default Checkout;
